<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Dettaglio programma</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <div class="data_container">
                    <div class="appuntamento_title">
                        {{ appuntamento.projects_name }}
                    </div>
                    <div class="field">
                        <div class="title">Cliente</div>
                        <div class="value">
                            {{ riferimentoCliente(appuntamento) }}
                        </div>
                    </div>
                    <div v-if="appuntamento.projects_city && appuntamento.projects_address" class="field">
                        <div class="title">Indirizzo</div>
                        <div class="value">
                            <a
                                :href="
                                    `https://maps.google.com/?q=${appuntamento.projects_city} ${appuntamento.projects_address} ${appuntamento.projects_province}`
                                "
                                target="_blank"
                                class="mobile_link"
                            >
                                <span>{{ appuntamento.projects_address }}</span>
                                <span>{{ appuntamento.projects_city }}</span>
                            </a>
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Data</div>
                        <div class="value">
                            {{ dateFormat(appuntamento.appuntamenti_giorno) }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Orari</div>
                        <div class="value">
                            {{ `${appuntamento.appuntamenti_ora_inizio} - ${appuntamento.appuntamenti_ora_fine}` }}
                        </div>
                    </div>
                    <div class="field field_list">
                        <div class="title">Persone</div>
                        <div class="persone" v-for="(persona, index) in appuntamento.appuntamenti_persone" :key="index">
                            <div>{{ persona }}</div>
                        </div>
                    </div>
                    <div v-if="appuntamento.appuntamenti_automezzi" class="field field_list">
                        <div class="title">Automezzi</div>
                        <div class="automezzi" v-for="(automezzo, index) in appuntamento.appuntamenti_automezzi" :key="index">
                            <div>{{ automezzo }}</div>
                        </div>
                    </div>
                    <div v-if="appuntamento.appuntamenti_note" class="field field_description">
                        <div class="title">Note</div>
                        <div class="value">
                            {{ appuntamento.appuntamenti_note ? `${appuntamento.appuntamenti_note}` : "-" }}
                        </div>
                    </div>

                    <div v-if="!rapportinoCreato" class="container_button">
                        <div class="btn_crea_rapportino" @click="newRapportino()">Crea rapportino</div>
                    </div>
                    <div v-else class="existing_rapportino">Rapportino già creato</div>

                    <div v-if="appuntamento.projects_permetti_ordine_materiali" class="container_button">
                        <div class="btn_crea_rapportino" @click="ordinaMateriali()">Ordina materiali</div>
                    </div>
                </div>

                <div v-if="services && services.length != 0" class="data_container services_container">
                    <div class="title">Servizi da svolgere</div>
                    <div v-for="(service, index) in services" :key="index" class="box_services">
                        <div v-if="service.gestione_servizi_servizi && service.gestione_servizi_servizi.length != 0">
                            <div v-for="(servizio, index) in service.gestione_servizi_servizi" :key="index" class="single_service">
                                {{ servizio }}
                            </div>
                        </div>
                    </div>
                </div>
                
                <div v-if="products && products.length != 0" class="data_container products_container">
                    <div class="title">Prodotti associati</div>
                        <div v-for="(product, index) in products" :key="index" class="single_prodotto" @click="openProductDetail(product)">
                            <span>{{ product.fw_products_name }}</span>
                            <span v-if="product.project_products_quantity && product.project_products_quantity > 0">
                                {{ `(${product.project_products_quantity})` }}
                            </span>
                        </div>
                </div>

                <!-- <div class="container_button">
                    <div class="btn_crea_rapportino" @click="newRapportino()">Crea rapportino</div>
                </div> -->
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    modalController,
} from "@ionic/vue";
import { arrowBackOutline, arrowForwardCircle } from "ionicons/icons";

import { defineComponent, ref, onMounted } from "vue";

import moment from "moment";

import { dateFormat } from "@/services/utils";
import { openToast } from "@/services/toast";

import NuovoRapportinoAppuntamento from "@/components/rapportini/NuovoRapportinoAppuntamento.vue";
import DettaglioRapportino from "@/components/rapportini/DettaglioRapportino.vue";
import ModalNuovoOrdine from "../ordini/ModalNuovoOrdine.vue";
import ModalNuovoOrdineAppuntamento from "../ordini/ModalNuovoOrdineAppuntamento.vue";
import DettaglioProdotto from "@/components/programma_lavoro/DettaglioProdotto.vue";
//import InterventoProgramma from "@/components/interventi/InterventoProgramma.vue";
//import DettaglioRapportino from "@/components/rapportini/DettaglioRapportino.vue";

import apiProdotti from "@/services/prodotti";

export default defineComponent({
    name: "ProgrammaLavoroDetail",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonContent,
        IonIcon,
        IonButton,
    },
    setup(props, context) {
        //console.log(props.data);
        const loading = ref(false);
        const appuntamento = ref([]);
        appuntamento.value = { ...props.data };
        //console.log(appuntamento.value);
        const rapportinoCreato = ref(appuntamento.value.rapportino_creato);

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };
        /**
         * ! Close modal after form data submit
         */
        async function closeModalOnSubmit(flag, res) {
            if (flag) {
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }


        /**
         * 
         * ! Open rapportino detail
         * 
         */
        async function openRapportinoDetail(rapportino) {
            const modal = await modalController.create({
                component: DettaglioRapportino,
                componentProps: {
                    data: rapportino,
                },
            });
            modal.onDidDismiss().then((detail) => {
                //Posso aver aperto questa pagina solo dopo aver creato un rapportino
                //quindi marco nuovamente il rapporitno come creato
                rapportinoCreato.value = true;
            });
            return modal.present();
        }

        /**
         * ! Open modal to create new rapportino
         */
        async function newRapportino() {
            const modal = await modalController.create({
                component: NuovoRapportinoAppuntamento,
                componentProps: {
                    data: appuntamento.value,
                },
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    //openDetail(detail.data);
                    rapportinoCreato.value = true;
                    //closeModalOnSubmit(true, detail.data);
                    openRapportinoDetail(detail.data);
                    openToast("Rapportino salvato con successo", "toast_success");
                }
            });
            return modal.present();
        }


        /**
         * 
         * ! Open order modal
         * 
         */
        async function ordinaMateriali() {
            const modal = await modalController.create({
                component: ModalNuovoOrdineAppuntamento,
                componentProps: {
                    data: appuntamento.value,
                },
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    console.log(detail.data);
                    openToast("Ordine salvato con successo", "toast_success");
                }
            });
            return modal.present();
        }


        /**
         * ! Print correct customer info
         */
        const riferimentoCliente = (cliente) => {
            if (cliente.customers_company) {
                return cliente.customers_company;
            } else if (cliente.customers_name && !cliente.customers_last_name) {
                return `${cliente.customers_name}`;
            }
            return `${cliente.customers_name} ${cliente.customers_last_name}`;
        };

        /**
         * ! Format datetime to DD/MM/YY HH:mm
         */
        function dateTimeFormat(dateTime) {
            if (dateTime && moment.isDate(new Date(dateTime))) {
                return moment(dateTime).format("DD/MM/YYYY HH:mm");
            } else {
                return "-";
            }
        }

        /**
         * ! Load prodotti
         */
        const products = ref([]);
        const loadingProducts = ref(false);
        async function loadProducts() {
            try {
                const res = await apiProdotti.getProdottiCommessa(appuntamento.value.appuntamenti_impianto);
                if (res.status === 200 && res.data.status === 0) {
                    products.value = res.data.data;
                } else {
                    openToast("Si è verificato un errore durante la richiesta dei prodotti", "toast_danger");
                }
            } catch (error) {
                openToast("Si è verificato un errore durante la richiesta dei prodotti", "toast_danger");
            } finally {
                loadingProducts.value = false;
            }
        }

        /*******************************************************
         *
         * ! Mostra servizi associati alla commessa dell'appuntamento visualizzato
         * ? 09/05/2024 - Aggiunto filtro in base alla giornata dell'appuntamento dato che i servizi possono esssere associati a singole giornate
         *
         ******************************************************/
        const loadingServices = ref(false);
        const services = ref([]);
        let gestione_servizi = [];
        async function loadServices() {
            loadingServices.value = true;

            // Ottengo n° giorno settimana (1 = Lunedì, 2 = Martedì, ..., 7 = Domenica) rispetto alla data dell'appuntamento
            const dayNumber = moment(appuntamento.value.appuntamenti_giorno).isoWeekday();

            try {
                const res = await apiProdotti.getFilteredServizi(appuntamento.value.appuntamenti_impianto, dayNumber);
                console.log(res);
                
                if (res.status === 200 && res.data.status === 0) {
                    services.value = res.data.data;

                    gestione_servizi = res.data.data.flatMap(function(item) {
                        return Object.entries(item.gestione_servizi_servizi).map(function([key, value]) {
                            return { id: key, name: value };
                        });
                    });
                    console.log(gestione_servizi);
                } else {
                    openToast("Errore durante la richiesta dei servizi relativi alla commessa", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei servizi relativi alla commessa", "toast_danger");
            } finally {
                //console.log(customers.value);
                loadingServices.value = false;
            }
        }

        /*******************************************************
         *
         * ! Open modal to display product info
         *
         ******************************************************/
         async function openProductDetail(product) {
            const modal = await modalController.create({
                component: DettaglioProdotto,
                componentProps: {
                    prodotto: product,
                },
            });
            return modal.present();
        }

        onMounted(() => {
            loadProducts();
            loadServices();
        });

        return {
            loading,
            dateFormat,
            arrowBackOutline,
            closeModal,
            arrowForwardCircle,
            appuntamento,
            dateTimeFormat,
            newRapportino,
            riferimentoCliente,
            rapportinoCreato,
            ordinaMateriali,
            // Prodotti & servizi
            openProductDetail,
            products,
            services
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
ion-content {
    --background: #f2f2f2;
}
ion-button {
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bg-primary {
    background: var(--ion-color-primary, #3880ff) !important;
    padding: 8px;
    color: #ffffff;
}

ion-item {
    font-size: 14px;
}
ion-label {
    margin: 0;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new richiesta btn */
    padding: 16px;
    min-height: 100%;
    background: #f2f2f2;
}

/* lead v2*/
.data_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 16px;
    border-radius: 8px;
    /*box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);*/
    background: #ffffff;
    box-shadow: 0px 2px 5px 0px #00000040;
}
.data_container .appuntamento_title {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: #1d1d1b;
    margin-bottom: 16px;
}
.data_container .city {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
    margin-bottom: 8px;
}
.data_container .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0px;
    border-bottom: 0.5px solid #7676803d;
}
.data_container .field_list {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 16px 0px;
    border-bottom: 0.5px solid #7676803d;
}
.data_container .field_list .title {
    margin-bottom: 4px;
}
.data_container .field_list .persone,
.data_container .field_list .automezzi {
    font-size: 14px;
}
.data_container .field_description {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border-bottom: none;
}
.data_container .field_description .title {
    width: 100%;
    margin-bottom: 4px;
}
.data_container .field:last-of-type {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px 0px;
    border-bottom: none;
}
.data_container .field_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
    border-bottom: none;
}

.field .title,
.field_note .title {
    font-size: 14px;
    font-weight: 500;
    color: #1d1d1b;
}
.field_note .title {
    margin-bottom: 8px;
}
.field .value,
.field_note .value {
    font-size: 14px;
}
.value .badge {
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}

.container_button {
    width: 100%;
    margin-top: 16px;
}
.btn_crea_rapportino {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 12px;
    margin-bottom: 8px;
    /* color: #ffffff;
    background-color: #086fa3; */
    background-color: rgb(219 234 254);
    color: rgb(37 99 235);
}

.partecipazione {
    margin-top: 32px;
    font-size: 16px;
    font-weight: 500;
    color: #475569;
    text-align: center;
}

.existing_rapportino {
    width: 100%;
    padding: 8px 12px;
    font-size: 14px;
    margin-top: 12px;
    text-align: center;
    border-radius: 4px;
    font-weight: bold;
    background-color: rgb(219 234 254);
    color: rgb(37 99 235);
}

.products_container,
.services_container {
    margin-top: 16px;
    max-height: 300px;
    overflow-y: scroll;
}
.products_container .title,
.services_container .title {
    font-size: 16px;
    font-weight: 600;
    color: #1d1d1b;
    margin-bottom: 8px;
}
.single_prodotto {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}
.single_service {
    width: 100%;
    margin-bottom: 8px;
}
.box_services {
    width: 100%;
}
</style>
