<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title color="white">Nuovo rapportino</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <!-- Products selection, triggered with click on select client in form -->
                <Transition name="fade-transition">
                    <div v-show="showProductsModal" class="customer_modal">
                        <div class="customers_container">
                            <input type="text" placeholder="Cerca prodotto" v-model="searchQuery" class="search_query_input" />
                            <div v-if="searchedProducts.length > 0" class="searched_result_list">
                                <div
                                    v-for="product in searchedProducts"
                                    :key="product.fw_products_id"
                                    @click="setSelectedProduct(product)"
                                    class="product"
                                    :class="{ selected_product: isSelectedProduct(product) }"
                                >
                                    {{ product.fw_products_name }}
                                </div>
                            </div>
                            <div v-else class="searched_result_list no_result">
                                Nessun prodotto associato alla commessa selezionata
                            </div>
                        </div>
                        <div @click="closeProductsSelection()" class="close_modal">Chiudi</div>
                    </div>
                </Transition>

                <form @submit.prevent="createRapportino()">
                    <ion-list class="fields">
                        <!-- Orari previsti -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title product_selection_trigger">
                                    Orari previsti
                                </div>
                                <div class="value">
                                    {{ dateFormat(appuntamento.appuntamenti_giorno) }}, {{ appuntamento.appuntamenti_ora_inizio }} -
                                    {{ appuntamento.appuntamenti_ora_fine }}
                                </div>
                            </div>
                        </ion-item>

                        <!-- Cliente -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title product_selection_trigger">
                                    Cliente
                                </div>
                                <div class="value">
                                    {{ appuntamento.customers_full_name }}
                                </div>
                            </div>
                        </ion-item>

                        <!-- Commessa -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title product_selection_trigger">
                                    Commessa
                                </div>
                                <div class="value">
                                    {{ appuntamento.projects_name }}
                                </div>
                            </div>
                        </ion-item>

                        <!-- Ora inizio -->
                        <div class="flex_container">
                            <div class="field">
                                <div class="field_title">
                                    Ora inizio
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="ora_inizio"
                                        display-format="HH:mm"
                                        picker-format="HH:mm"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="newRapportino.ora_inizio"
                                        placeholder="Seleziona ora di inizio"
                                    ></ion-datetime>
                                </div>
                            </div>
                            <div class="field">
                                <div class="field_title">
                                    Ora fine
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="ora_fine"
                                        display-format="HH:mm"
                                        picker-format="HH:mm"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="newRapportino.ora_fine"
                                        placeholder="Seleziona ora di inizio"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </div>

                        <!-- Operatori -->
                        <ion-item v-if="showOperatori" lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Operatori
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-select
                                        name="tecnici"
                                        multiple="true"
                                        cancel-text="Annulla"
                                        ok-text="Conferma"
                                        :placeholder="showPlaceholderTextTecnici()"
                                        v-model="newRapportino.operatori"
                                    >
                                        <ion-select-option v-for="tecnico in tecnici" :key="tecnico.users_id" :value="tecnico.users_id">
                                            {{ `${tecnico.users_last_name} ${tecnico.users_first_name}` }}
                                        </ion-select-option>
                                    </ion-select>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Prodotti -->
                        <ion-item v-if="showProdotti" lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title product_selection_trigger">
                                    Prodotti
                                    <div @click="openProductsSelection()" class="trigger">seleziona</div>
                                </div>
                                <div class="value">
                                    <div v-if="selectedProducts.length != 0">
                                        <div v-for="(prod, index) in selectedProducts" :key="index">
                                            {{ prod.fw_products_name }}
                                        </div>
                                    </div>
                                    <div v-else>Nessun prodotto selezionato</div>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Descrizione -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Note
                                </div>
                                <div class="value">
                                    <ion-textarea
                                        type="text"
                                        rows="4"
                                        v-model="newRapportino.descrizione"
                                        placeholder="Note lavoro svolto"
                                        class="custom_input"
                                    >
                                    </ion-textarea>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Checklist rapportino basata sul tipo commessa -->
                        <div v-if="loadingSondaggio" class="load_sondaggio_container">
                            <div class="load_sondaggio_text">
                                Ricerca checklist in corso...
                            </div>
                        </div>

                        <div v-if="sondaggio" class="checklist_container">
                            <div v-for="(step, i) in sondaggio" :key="i" class="single_step">
                                <div class="single_step_title">{{ step.step }}</div>
                                <div v-for="(domanda, j) in step.domande" :key="j" class="single_field">
                                    <div class="domanda_title">{{ domanda.sondaggi_domande_domanda }}</div>

                                    <!-- Input type (risposta breve) -->
                                    <div v-if="domanda.sondaggi_domande_tipologia_value === 'Risposta breve'">
                                        <ion-input type="text" v-model="formFields[domanda.sondaggi_domande_id]"> </ion-input>
                                    </div>

                                    <!-- Textarea (risposta paragrafo) -->
                                    <div v-if="domanda.sondaggi_domande_tipologia_value === 'Paragrafo'">
                                        <ion-textarea v-model="formFields[domanda.sondaggi_domande_id]"> </ion-textarea>
                                    </div>

                                    <!-- Select (risposta singola) -->
                                    <div v-if="domanda.sondaggi_domande_tipologia_value === 'Risposta singola'">
                                        <ion-select
                                            interface="action-sheet"
                                            cancel-text="Annulla"
                                            ok-text="Conferma"
                                            v-model="formFields[domanda.sondaggi_domande_id]"
                                        >
                                            <ion-select-option
                                                v-for="(option, index) in domanda.risposte"
                                                :key="index"
                                                :value="option.sondaggi_domande_risposte_id"
                                            >
                                                {{ `${option.sondaggi_domande_risposte_risposta}` }}
                                            </ion-select-option>
                                        </ion-select>
                                    </div>

                                    <!-- Radio (risposta singola) -->
                                    <div v-if="domanda.sondaggi_domande_tipologia_value === 'Risposta singola - radio'">
                                        <ion-radio-group
                                            @ionChange="setRadioRisposta($event, domanda.sondaggi_domande_id)"
                                            v-model="formFields[domanda.sondaggi_domande_id]"
                                        >
                                            <ion-item
                                                v-for="(option, index) in domanda.risposte"
                                                :key="index"
                                                lines="none"
                                                class="ion-no-padding ion-no-margin"
                                                style="height: 40px;"
                                            >
                                                <ion-label class="ion-no-padding ion-no-margin">{{ `${option.sondaggi_domande_risposte_risposta}` }}</ion-label>
                                                <ion-radio
                                                    slot="start"
                                                    :value="option.sondaggi_domande_risposte_id"
                                                    class="ion-no-padding ion-no-margin ion-margin-end"
                                                ></ion-radio>
                                            </ion-item>
                                        </ion-radio-group>
                                    </div>

                                    <!-- Select (scelta multipla) -->
                                    <!-- <div v-if="domanda.sondaggi_domande_tipologia_value === 'Risposta multipla - checkbox'">
                                        <ion-radio-group
                                            :value="domanda.risposte[0].sondaggi_domande_risposte_id"
                                            @ionChange="setCheckboxRisposta($event, domanda.sondaggi_domande_id)"
                                        >
                                            <ion-item
                                                v-for="(option, index) in domanda.risposte"
                                                :key="index"
                                                lines="none"
                                                class="ion-no-padding ion-no-margin"
                                                style="height: 40px;"
                                            >
                                                <ion-label class="ion-no-padding ion-no-margin">{{ `${option.sondaggi_domande_risposte_risposta}` }}</ion-label>
                                                <ion-checkbox
                                                    slot="start"
                                                    :value="option.sondaggi_domande_risposte_id"
                                                    class="ion-no-padding ion-no-margin ion-margin-end"
                                                ></ion-checkbox>
                                            </ion-item>
                                        </ion-radio-group>
                                    </div> -->
                                    <div v-if="domanda.sondaggi_domande_tipologia_value === 'Risposta multipla - checkbox'">
                                        <ion-select
                                            multiple
                                            v-model="formFields[domanda.sondaggi_domande_id]"
                                            @ionChange="setMultipleSelectRisposta($event, domanda.sondaggi_domande_id)"
                                        >
                                            <ion-select-option
                                                v-for="(option, index) in domanda.risposte"
                                                :key="index"
                                                :value="option.sondaggi_domande_risposte_id"
                                            >
                                                {{ option.sondaggi_domande_risposte_risposta }}
                                            </ion-select-option>
                                        </ion-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Foto  -->
                        <!--  <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Foto
                                </div>
                                <div class="value">
                                    <div v-if="fotoRapportino.length == 0" class="no_data">
                                        Nessuna foto selezionata
                                    </div>
                                    <div v-else>
                                        <div class="foto_container">
                                            <div class="single_foto" v-for="(foto, index) in fotoRapportino" :key="index">
                                                <ion-thumbnail>
                                                    <img :src="`data:image/jpeg;base64,${foto}`" />
                                                </ion-thumbnail>
                                                <div class="remove_photo" @click="removePhoto(index)">Rimuovi</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ion-item> -->

                        <div class="actions_firme">
                            <button
                                :disabled="!richiediFirmaCliente"
                                type="button"
                                class="btn_firma"
                                :class="{ btn_signed: customerHasSigned }"
                                @click="openSignature('cliente')"
                            >
                                Firma cliente
                            </button>
                            <button
                                :disabled="!richiediFirmaTecnico"
                                type="button"
                                class="btn_firma"
                                :class="{ btn_signed: technicianHasSigned }"
                                @click="openSignature('tecnico')"
                            >
                                Firma tecnico
                            </button>
                        </div>

                        <div class="action">
                            <!-- <div>
                            <button type="button" class="btn_carica_foto" @click="openFilePicker">Seleziona foto</button>
                            <input ref="fileInput" type="file" @change="handleFileChange" accept="image/*" multiple hidden />
                        </div> -->
                            <button type="submit" class="btn_crea_rapportino" :disabled="isCreatingRapportino">
                                <span v-if="isCreatingRapportino">Salvataggio in corso...</span>
                                <span v-else>Salva</span>
                            </button>
                        </div>
                    </ion-list>
                </form>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    modalController,
    IonList,
    IonItem,
    IonDatetime,
    IonTextarea,
    IonText,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonRadioGroup,
    IonRadio,
    IonLabel,
    IonIcon,
    //IonThumbnail
} from "@ionic/vue";
import { arrowBackOutline, close } from "ionicons/icons";

import { defineComponent, ref, reactive, computed, toRefs, toRaw, onMounted } from "vue";

import { LOCALSTORAGE_KEY, showOperatori, showProdotti } from "@/custom_config";

import moment from "moment";

import { dateFormat } from "@/services/utils";
import { openToast } from "@/services/toast";

import apiRapportini from "@/services/rapportini";
import apiTecnici from "@/services/tecnici";
import apiProdotti from "@/services/prodotti";
import apiSondaggi from "@/services/sondaggi";

import FirmaRapportino from "@/components/firme/FirmaRapportino";

export default defineComponent({
    name: "NuovoRapportinoAppuntamento",
    props: {
        data: {
            type: Object,
        },
    },
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonButton,
        IonList,
        IonItem,
        IonDatetime,
        IonTextarea,
        IonText,
        IonInput,
        IonSelect,
        IonSelectOption,
        IonRadioGroup,
        IonRadio,
        IonLabel,
        IonIcon,
        //IonThumbnail
    },
    setup(props, context) {
        const appuntamento = { ...props.data };

        const userID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_user_id;
        const start_date = new Date();
        const start_hour = new Date();
        const end_hour = new Date();
        start_hour.setHours(start_hour.getHours() - 1);
        end_hour.setHours(end_hour.getHours() + 2);

        const isCreatingRapportino = ref(false);

        // Convert time field to ISO format
        function createDateFromTimeString(timeString) {
            const currentDate = moment();
            const dateString = currentDate.format("YYYY-MM-DD");
            const dateTimeString = `${dateString} ${timeString}`;

            return moment(dateTimeString, "YYYY-MM-DD HH:mm").toISOString();
        }

        const newRapportino = reactive({
            cliente: appuntamento.appuntamenti_cliente,
            commessa: appuntamento.appuntamenti_impianto,
            data: appuntamento.appuntamenti_giorno,
            ora_inizio: createDateFromTimeString(appuntamento.appuntamenti_ora_inizio),
            ora_fine: createDateFromTimeString(appuntamento.appuntamenti_ora_fine),
            operatori: [],
            note: null,
            firma_tecnico: null,
            firma_cliente: null,
            prodotti: [],
        });

        const successResponse = ref(false);

        // Gestione settings commessa
        const richiediFirmaCliente = ref(false);
        const richiediFirmaTecnico = ref(false);

        function checkSettingsCommessa() {
            richiediFirmaCliente.value = appuntamento.projects_richiesta_firma_cliente && appuntamento.projects_richiesta_firma_cliente == "1" ? true : false;
            richiediFirmaTecnico.value =
                appuntamento.projects_richiesta_firma_operatore && appuntamento.projects_richiesta_firma_operatore == "1" ? true : false;
        }
        checkSettingsCommessa();

        /**
         *
         * ! Open modal to sign for technician and customer
         *
         */
        const technicianHasSigned = ref(false);
        const customerHasSigned = ref(false);
        async function openSignature(signatureType) {
            const modal = await modalController.create({
                component: FirmaRapportino,
                componentProps: {
                    data: signatureType,
                    firma: signatureType === "tecnico" ? newRapportino.firma_tecnico : newRapportino.firma_cliente,
                },
            });
            modal.onDidDismiss().then((detail) => {
                //console.log(detail.data);
                if (detail.data) {
                    // Signed, change button clas
                    if (detail.data.firma && detail.data.tipo) {
                        if (detail.data.tipo === "tecnico") {
                            newRapportino.firma_tecnico = detail.data.firma;
                            if (detail.data.firma && detail.data.firma.length != 0) {
                                technicianHasSigned.value = true;
                            }
                        } else {
                            newRapportino.firma_cliente = detail.data.firma;
                            if (detail.data.firma && detail.data.firma.length != 0) {
                                customerHasSigned.value = true;
                            }
                        }
                    }
                    // Removed signature or closed modal, remove signature from rapportino
                    if (detail.data.da_cancellare === true && detail.data.tipo) {
                        if (detail.data.tipo === "tecnico") {
                            newRapportino.firma_tecnico = null;
                            technicianHasSigned.value = false;
                        } else {
                            newRapportino.firma_cliente = null;
                            customerHasSigned.value = false;
                        }
                    }
                }
            });
            return modal.present();
        }

        /**
         *
         * ! Load products data
         * ! Products selection on custom modal
         *
         */

        const prodotti = ref([]);
        const selectedProducts = ref([]);

        async function loadProdotti(commessaId) {
            // Svuoto evenuali prodotti selezionati
            selectedProducts.value = [];
            try {
                const res = await apiProdotti.getProdottiCommessa(commessaId);                

                if (res.status === 200 && res.data.status === 0) {
                    prodotti.value = res.data.data;
                } else {
                    openToast("Si è verificato un errore durante la richiesta dei prodotti", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Si è verificato un errore durante la richiesta dei prodotti", "toast_danger");
            }
        }

        const showProductsModal = ref(false);
        function openProductsSelection() {
            showProductsModal.value = true;
        }

        function closeProductsSelection() {
            showProductsModal.value = false;
        }

        const searchQuery = ref("");
        const normalizeStringProduct = (str) => str.toLowerCase().replace(/ /g, "");

        const searchedProducts = computed(() => {
            const term = normalizeStringProduct(searchQuery.value);

            return prodotti.value.filter((product) => {
                return product.fw_products_name.includes(term);
            });
        });

        function setSelectedProduct(product) {
            if (product) {
                const index = selectedProducts.value.findIndex((p) => p.fw_products_id === product.fw_products_id);

                if (index === -1) {
                    // Aggiunro prodotto
                    searchQuery.value = "";
                    selectedProducts.value.push(product);
                } else {
                    // Rimuovo prodotto
                    selectedProducts.value.splice(index, 1);
                }
            }
        }

        const isSelectedProduct = (product) => {
            console.log(product);
            console.log(selectedProducts.value);
            
            return selectedProducts.value.some((p) => p.fw_products_id === product.fw_products_id);
        };

        /**
         *
         * ! Close modal after form data submit
         *
         */
        async function closeModalOnSubmit(successResponse, res) {
            if (successResponse.value) {
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }

        /**
         *
         * ! Handle close page
         *
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         *
         * ! Load technicians data
         *
         */
        const tecnici = ref([]);
        async function loadTecnici() {
            try {
                const res = await apiTecnici.getTecnici();

                if (res.status === 200 && res.data.status === 0) {
                    tecnici.value = res.data.data;
                } else {
                    openToast("Si è verificato un errore durante la richiesta dei tecnici", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Si è verificato un errore durante la richiesta dei tecnici", "toast_danger");
            }
        }

        /**
         * ! Load photos from input file
         */
        const fotoRapportino = ref([]);
        const fileInput = ref(null);

        const openFilePicker = () => {
            fileInput.value.click();
        };

        const handleFileChange = (event) => {
            const files = event.target.files;
            if (files.length === 0) return;

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const reader = new FileReader();

                reader.onload = (e) => {
                    const base64String = e.target.result.split(",")[1];
                    fotoRapportino.value.push(base64String);
                };

                reader.readAsDataURL(file);
            }
        };

        function removePhoto(index) {
            if (fotoRapportino.value.length > 0) {
                fotoRapportino.value.splice(index, 1);
            }
        }

        //Ricerca sondaggio associato all'appuntammento
        const sondaggio = ref(null);
        const loadingSondaggio = ref(false);
        async function loadSondaggio(appuntamento_id) {
            loadingSondaggio.value = true;
            //Disabilito button per evitare creazione rapportino se la richiesta della checklist ci mette troppo
            isCreatingRapportino.value = true;

            try {
                const res = await apiSondaggi.getSondaggioAppuntamento(appuntamento_id);

                if (res.data.status === 1) {
                    sondaggio.value = res.data.data;
                } else {
                    openToast(`Checklist non riconosciuta. Contattare l'assistenza`, "toast_danger");
                    //openToast(`${res.data.txt}`, "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta della checklist", "toast_danger");
            } finally {
                loadingSondaggio.value = false;
                isCreatingRapportino.value = false;
            }
        }

        /**
         *
         * ! SONDAGGIO E CREAZIONE RAPPORTINO
         *
         */
        /**
         * ! Contenitore v-model del form sondaggio
         * */
        const formFields = ref({});
        /**
         * ! Set risposta value based on selected radio
         */
        function setRadioRisposta(event, reference) {
            const value = event.target.value;
            formFields.value[reference] = value;
        }
        /**
         * ! Set risposta value based on selected checkbox
         * ? Devo costruirmi un array con le risposte
         */
        let risposteCheckbox = [];
        function setCheckboxRisposta(event, reference) {
            const value = event.target.value;

            // Deselezione
            if (risposteCheckbox.includes(value)) {
                const tmp = risposteCheckbox.filter((el) => el == value);
                risposteCheckbox = tmp;
            } else {
                // Selezione
                risposteCheckbox.push(value);
            }

            formFields.value[reference] = [...risposteCheckbox];
        }

        function setMultipleSelectRisposta(selectedValues, domandaId) {
            // Gestisci la selezione multipla qui, ad esempio, memorizzando i valori selezionati nel tuo data o eseguendo altre azioni necessarie.
            const values = event.detail.value;
            /* console.log("Valori selezionati:", selectedValues);
            console.log("ID della domanda:", domandaId); */
        }

        /**
         * ! Crea sondaggio legato da associare al rapporitno
         */
        /* async function createSondaggio() {
            const response = {
                data: null,
                success: false,
            };

            if (sondaggio.value && Object.keys(formFields.value).length === 0) {
                isCreatingRapportino.value = false;
                openToast("Non è stata fornita alcuna risposta alla checklist", "toast_danger");
                return response;
            }

            //Se ho un sondaggio provo a crearlo
            if (sondaggio.value) {
                // Prendo le referenze alle proprietà dell'oggetto reattivo
                const refs = toRefs(formFields.value);
                // Mi costruisco muovo oggetto nell formato corretto
                const formattedObject = {};
                for (const key in refs) {
                    formattedObject[`risposta[${key}]`] = refs[key].value;
                }

                //Mi creo l'oggetto FormData
                const formData = new FormData();
                for (const key in formattedObject) {
                    formData.append(key, formattedObject[key]);
                }
                for (const key in refs) {
                    // Se il valore è un array (selezione multipla), itera attraverso di esso
                    if (Array.isArray(refs[key].value)) {
                        for (const selectedValue of refs[key].value) {
                            formData.append(`risposta[${key}][]`, selectedValue);
                        }
                    } else {
                        // Se il valore non è un array, aggiungilo direttamente
                        formData.append(`risposta[${key}]`, refs[key].value);
                    }
                }

                formData.append("user_id", userID);
                formData.append("sondaggio_id", sondaggio.value[0].sondaggio_id);
                formData.append("sondaggio_da_app", 1);

                try {
                    const res = await apiSondaggi.saveCompilazione(formData);

                    if (res.data.status == 0) {
                        openToast(`${res.data.txt}`, "toast_danger");
                        response.success = false;
                        return response;
                    } else if (res.data.status == 1) {
                        response.data = res.data;
                        response.success = true;
                        return response;
                    }
                } catch (error) {
                    console.error(error);
                    openToast("Si è verificato un errore durante il salvataggio della checklist", "toast_danger");
                    return response;
                } finally {
                    isCreatingRapportino.value = false;
                }
            }
        } */
        async function createSondaggio() {
            const response = {
                data: null,
                success: false,
            };

            if (sondaggio.value && Object.keys(formFields.value).length === 0) {
                isCreatingRapportino.value = false;
                openToast("Non è stata fornita alcuna risposta alla checklist", "toast_danger");
                return response;
            }

            if (sondaggio.value) {
                const formData = new FormData();
                const rawFormFields = toRaw(formFields.value); // Converte l'oggetto reattivo in un normale oggetto

                Object.keys(rawFormFields).forEach((key) => {
                    const value = rawFormFields[key];
                    if (Array.isArray(value)) {
                        value.forEach((selectedValue) => formData.append(`risposta[${key}][]`, selectedValue));
                    } else {
                        formData.append(`risposta[${key}]`, value);
                    }
                });

                formData.append("user_id", userID);
                formData.append("sondaggio_id", sondaggio.value[0].sondaggio_id);
                formData.append("sondaggio_da_app", 1);

                try {
                    const res = await apiSondaggi.saveCompilazione(formData);
                    if (res.data.status === 0) {
                        openToast(res.data.txt || "Errore durante il salvataggio della checklist", "toast_danger");
                    } else if (res.data.status === 1) {
                        response.data = res.data;
                        response.success = true;
                    }
                } catch (error) {
                    console.error(error);
                    openToast("Errore durante il salvataggio della checklist", "toast_danger");
                } finally {
                    isCreatingRapportino.value = false;
                }
            }

            return response;
        }

        function resetFormData() {
            //Reset dato rapportino
            newRapportino.cliente = appuntamento.appuntamenti_cliente;
            newRapportino.appuntamento = appuntamento.appuntamenti_id;
            newRapportino.commessa = appuntamento.appuntamenti_impianto;
            newRapportino.data = moment(appuntamento.appuntamenti_giorno).format("YYYY-MM-DD");
            newRapportino.ora_inizio = moment(start_hour).toISOString();
            newRapportino.ora_fine = moment(end_hour).toISOString();
            newRapportino.operatori = [userID];
            newRapportino.note = "";
            newRapportino.prodotti = [];
            newRapportino.firma_tecnico = "";
            newRapportino.firma_cliente = "";
            //Reset button firme
            customerHasSigned.value = false;
            technicianHasSigned.value = false;
        }

        /**
         * ! Show toast error message
         * @param {String} message - Messaggio da mostrare
         */
        function showError(message) {
            openToast(message, "toast_danger");
            return;
        }

        /**
         * ! Append data to FormData object
         * @param {formData} formData - FormData object to append data
         * @param {String} key - Key to append
         * @param {any} value - Value to append
         * @param {Boolean} isArray - If value is an array
         * @param {Array} array - Array to iterate
         * @param {String} fieldKey - Field to iterate in array
         */
        function appendData(formData, key, value = null, isArray = false, array = [], fieldKey = null) {
            if (!isArray && value && value !== "") {
                formData.append(key, value);
            }

            if (isArray && Array.isArray(array) && array.length > 0) {
                array.forEach((el) => {
                    formData.append(key, el[fieldKey]);
                });
            }
        }

        /**
         * ! Create new rapportino
         */
        async function createRapportino() {
            if (!appuntamento.appuntamenti_id) {
                showError("L'appuntamneto collegato non è stato rilevato", "toast_danger");
            }
            // Controllo cliente
            if (!newRapportino.cliente) {
                showError("Il cliente è obbligatorio", "toast_danger");
            }
            // Controllo commessa
            if (!newRapportino.commessa) {
                showError("La commessa è obbligatoria", "toast_danger");
            }
            //Controllo che ora inizio non sia successiva ad ora fine
            const ora_inizio = new Date(newRapportino.ora_inizio);
            const ora_fine = new Date(newRapportino.ora_fine);
            if (ora_inizio > ora_fine) {
                showError("L'ora di inizio non può essere maggiore dell'ora di fine", "toast_danger");
            }
            //Controllo che ci sia almeno un tecnico selezioanto
            if (newRapportino.operatori.length === 0) {
                showError("Non puoi creare il rapportino senza selezionare gli operatori", "toast_danger");
            }
            //Controllo di essere sempre tra i operatori selezionati
            const isUSerSelected = newRapportino.operatori.includes(userID);
            if (!isUSerSelected && newRapportino.operatori.length != 0) {
                showError("Non puoi creare il rapportino senza essere tra gli operatori selezionati", "toast_danger");
            }

            isCreatingRapportino.value = true;

            const data_formatted = moment(newRapportino.data).format("YYYY-MM-DD");
            const ora_inizio_formatted = moment(newRapportino.ora_inizio).format("HH:mm");
            const ora_fine_formatted = moment(newRapportino.ora_fine).format("HH:mm");

            const formData = new FormData();

            /* formData.append("rapportini_appuntamento_id", appuntamento.appuntamenti_id);
            formData.append("rapportini_cliente", newRapportino.cliente);
            formData.append("rapportini_commessa", newRapportino.commessa);
            formData.append("rapportini_data", data_formatted);
            formData.append("rapportini_ora_inizio", ora_inizio_formatted);
            formData.append("rapportini_ora_fine", ora_fine_formatted); */
            appendData(formData, "rapportini_appuntamento_id", appuntamento.appuntamenti_id);
            appendData(formData, "rapportini_cliente", newRapportino.cliente);
            appendData(formData, "rapportini_commessa", newRapportino.commessa);
            appendData(formData, "rapportini_data", data_formatted);
            appendData(formData, "rapportini_ora_inizio", ora_inizio_formatted);
            appendData(formData, "rapportini_ora_fine", ora_fine_formatted);
            // Operatori
            //appendData(formData, "rapportini_operatori[]", null, true, newRapportino.operatori, "users_id");
            newRapportino.operatori.forEach((operatore) => {
                formData.append("rapportini_operatori[]", operatore);
            });

            appendData(formData, "rapportini_note", newRapportino.note);
            /* if(newRapportino.note) {
                formData.append("rapportini_note", newRapportino.note);
            } */
            // Prodotti selezionati
            appendData(formData, "rapportini_prodotti[]", null, true, selectedProducts.value, "fw_products_id");
            /* if(selectedProducts.value.length > 0) {
                selectedProducts.value.forEach((prod) => {
                    formData.append('rapportini_prodotti[]', prod.fw_products_id);
                });
            }*/

            /* if(newRapportino.firma_tecnico) {
                formData.append("rapportini_firma_tecnico", newRapportino.firma_tecnico);
            } */
            appendData(formData, "rapportini_firma_tecnico", newRapportino.firma_tecnico);
            /* if(newRapportino.firma_cliente) {
                formData.append("rapportini_firma_cliente", newRapportino.firma_cliente);
            } */
            appendData(formData, "rapportini_firma_cliente", newRapportino.firma_cliente);

            /**
             * * Creo la compilazione e poi associo l'id della compilazione al rapportino
             */
            let compilazione_id = null;
            if (sondaggio.value && Object.keys(formFields.value).length === 0) {
                openToast("Non puoi salvare senza aver fornito alcuna risposta alla checklist", "toast_danger");
                isCreatingRapportino.value = false;
                return;
            }

            if (sondaggio.value) {
                const sondaggioCreato = await createSondaggio();
                //Creazione sondaggio fallita, blocco esecuzione
                if ((sondaggioCreato && !sondaggioCreato.data) || !sondaggioCreato.success) {
                    //Loading.dismissLoader();
                    showError("Si è verificato un erore durante il salvataggio della checklist", "toast_danger");
                } else {
                    compilazione_id = sondaggioCreato.data.compilazione_id;
                    //formData.append("rapportini_compilazione_id", compilazione_id);
                    appendData(formData, "rapportini_compilazione_id", compilazione_id);
                }
            }

            try {
                const response = await apiRapportini.saveRapportino(formData);
                if (response.data.status === 8 || response.data.status === 5) {
                    openToast(response.data.message, "toast_danger");
                } else {
                    // Svuoto dati form
                    resetFormData();
                    // Svuoto dai sondaggio
                    Object.assign(formFields, {});

                    const res = response.data.data[0];
                    successResponse.value = true;
                    closeModalOnSubmit(successResponse, res);
                }
            } catch (error) {
                console.error(error);
                openToast("Si è verificato un errore durante la creazione del rapportino", "toast_danger");
            } finally {
                isCreatingRapportino.value = false;
            }
        }

        // Imposta in automatico gli operatori dell'appuntamento
        function setOperatori() {
            if (appuntamento.appuntamenti_persone) {
                const keys = Object.keys(appuntamento.appuntamenti_persone);

                keys.forEach((key) => {
                    newRapportino.operatori.push(key);
                });
            }
        }

        const placeholderText = ref("");
        function showPlaceholderTextTecnici() {
            if (appuntamento.appuntamenti_persone) {
                const values = Object.values(appuntamento.appuntamenti_persone);

                return values.join(", ");
            }
        }

        onMounted(() => {
            // carica tecnici
            loadTecnici();
            // Carica prodotti in base a setting
            if (showProdotti) {
                loadProdotti(appuntamento.appuntamenti_impianto);
            }
            //Ricerca sondaggio per appuntamento selezionato
            if (appuntamento.appuntamenti_checklist) {
                loadSondaggio(appuntamento.appuntamenti_checklist);
            }
            // Carica e mostra operatori in base a setting
            setOperatori();
        });

        return {
            // Icon
            closeModal,
            arrowBackOutline,
            close,
            // Utilities
            dateFormat,
            // Data
            appuntamento,
            createRapportino,
            newRapportino,
            tecnici,
            showPlaceholderTextTecnici,
            placeholderText,
            // Signature
            richiediFirmaCliente,
            richiediFirmaTecnico,
            openSignature,
            technicianHasSigned,
            customerHasSigned,
            isCreatingRapportino,
            // Load photos
            fileInput,
            openFilePicker,
            handleFileChange,
            fotoRapportino,
            removePhoto,
            // Products
            prodotti,
            showProductsModal,
            searchQuery,
            searchedProducts,
            selectedProducts,
            openProductsSelection,
            closeProductsSelection,
            isSelectedProduct,
            setSelectedProduct,
            loadProdotti,
            // Sondaggio
            formFields,
            sondaggio,
            setRadioRisposta,
            //saveRapportino,
            setCheckboxRisposta,
            loadingSondaggio,
            //Select multiple
            setMultipleSelectRisposta,
            // Flag prodotti e operatori
            showProdotti,
            showOperatori,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.page_content {
    width: 100%;
    position: relative;
    min-height: 100%;
    background: #f2f2f2;
    padding: 16px;
}

.fields {
    width: 100%;
    /*border-radius: 4px;*/
    border-radius: 8px;
    /*padding: 10px;*/
    padding: 16px;
    /*box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);*/
    box-shadow: 0px 2px 5px 0px #00000040;
}
.field {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 8px;
}

.field .value {
    font-size: 14px;
}

.field_title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
    color: #000000;
}

.field_title.customer_selection {
    display: flex;
    justify-content: space-between;
}

.field_title.customer_selection .trigger {
    font-size: 10px;
    padding: 4px 8px;
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    text-transform: capitalize;
}

ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --padding-left: 0;
    --padding-right: 0;
    --inner-padding-end: 0;
    --ripple-color: transparent;
    --background-activated: transparent;
    --background-activated-opacity: 0;
    margin-bottom: 4px;
}

ion-datetime,
ion-select {
    max-width: 100%;
    /* border: 1px solid #d1d5db; */
    border: 1px solid #0000001a;
    border-radius: 6px;
    --padding-start: 12px;
    --padding-end: 12px;
    --padding-left: 12px;
    --padding-right: 12px;
    --placeholder-color: rgb(0, 0, 0);
    --placeholder-opacity: 1;
    opacity: 1;
}
ion-select::part(icon) {
    opacity: 1;
    color: #086fa3;
}

ion-input,
ion-textarea {
    --placeholder-color: #00000080;
    --padding-start: 12px !important;
    --padding-end: 12px !important;
    --padding-left: 12px !important;
    --padding-right: 12px !important;
    /* border: 1px solid #d1d5db; */
    border: 1px solid #0000001a;
    border-radius: 6px;
    margin-top: 0;
}
.custom_input textarea::placeholder {
    font-size: 5px;
}

.btn_crea_rapportino,
.btn_carica_foto {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    background-color: #086fa3;
    color: #ffffff;
}
.btn_crea_rapportino:disabled {
    touch-action: none;
    opacity: 0.55;
}
.btn_carica_foto {
    background-color: #94a3b8;
}

ion-button {
    --color: #ffffff;
}

.action {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.actions_firme {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
    gap: 16px;
}
.btn_firma {
    width: 50%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
}

.btn_firma:disabled {
    touch-action: none;
    opacity: 0.4;
}

.btn_firma.btn_signed {
    background-color: #086fa3;
    color: #ffffff;
    transition: all 0.15s ease-in;
}

/** Allegati */
.btn_allega_foto {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}

.foto_container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
    margin-top: 8px;
    /*margin-bottom: 16px;*/
}
.single_foto {
    margin-right: 16px;
    margin-bottom: 16px;
}
ion-thumbnail {
    --size: 120px;
    --border-radius: 4px;
}

.remove_photo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 8px;
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    border-radius: 4px;
    padding: 4px;
    font-size: 12px;
}
.flex_container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
}
.flex_container .field {
    width: 47%;
}

/**
* Products selection
*/
.field_title.product_selection_trigger {
    display: flex;
    justify-content: space-between;
}

.field_title.product_selection_trigger .trigger {
    font-size: 10px;
    padding: 4px 8px;
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    text-transform: capitalize;
}

.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: opacity 0.25s ease;
}

.fade-transition-enter-from,
.fade-transition-leave-to {
    opacity: 0;
}
.customer_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    padding: 24px;
    align-items: center;
}
.close_modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #086fa3;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    margin-top: 16px;
}
.search_query_input {
    width: 100%;
    border: 1px solid #c3c8cf;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 16px;
}
.search_query_input::placeholder {
    color: #64748b;
}
.search_query_input:focus-visible {
    /* outline: 1px solid #509843;*/
    outline: 1px solid #334155;
}
.customers_container {
    width: 100%;
    height: 80%;
    background-color: #ffffff;
    border-radius: 4px;
    color: #000000;
    padding: 12px;
    display: flex;
    flex-direction: column;
}
.searched_result_list {
    overflow-y: scroll;
}
.searched_result_list.no_result {
    text-align: center;
    font-weight: 500;
    color: #64748b;
}
.searched_result_list .product {
    padding: 4px 8px;
    margin-bottom: 8px;
}
.selected_product {
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    font-weight: 500;
}

.no_data {
    font-size: 14px;
}

/**
* Checklist
*/
.load_sondaggio_container {
    width: 100%;
    margin: 24px 0;
}

.load_sondaggio_text {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
}
/**
* Step domande
 */
.checklist_container {
    margin-top: 32px;
}
.single_step {
    margin-bottom: 32px;
}
.single_step .single_step_title {
    font-weight: bold;
    margin-bottom: 8px;
}
.single_step .single_field {
    margin-bottom: 16px;
}
.single_step .single_field .domanda_title {
    font-size: 14px;
    margin-bottom: 4px;
}
</style>
